const GithubBanner = () => (
  <a href="https://github.com/joeyschoblaska/inseason">
    <img
      style={{ position: "absolute", top: 0, right: 0, border: 0 }}
      src="https://s3.amazonaws.com/github/ribbons/forkme_right_darkblue_121621.png"
      alt="Fork me on GitHub"
    />
  </a>
);

export default GithubBanner;
